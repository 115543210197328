const mvSwiper = new Swiper('.top__mv-swiper', {
    slidesPerView: 1,
    loop: true,
    spaceBetween: 0,
    speed: 1000,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false,
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    effect: 'fade',

    on: {
        init: function () {
            this.slides.forEach((slide, index) => {
                const videoElement = slide.querySelector('video');
                const bullet = this.pagination.bullets ? this.pagination.bullets[index] : null;

                // ページネーションが存在し、video要素が含まれている場合に -video クラスを追加
                if (videoElement && bullet) {
                    bullet.classList.add('-video');
                }
            });
        },
        slideChange: function () {
            // 現在のスライドを取得
            const currentSlide = this.slides[this.activeIndex];
            // スライド内の画像とビデオ要素を取得
            const imgElement = currentSlide.querySelector('img');
            const videoElement = currentSlide.querySelector('video');

            // 要素の種類に応じてdelayを設定
            if (videoElement) {
                this.params.autoplay.delay = 15000; // videoなら15秒
            } else if (imgElement) {
                this.params.autoplay.delay = 5000; // imgなら5秒
            }

            // autoplayの設定を更新
            this.autoplay.start();

            // video要素が含まれているスライドをすべて確認して再生または一時停止
            this.slides.forEach((slide, index) => {
             
                const bullet = this.pagination.bullets ? this.pagination.bullets[index] : null;
        
                if (!bullet) return;
                
                slide.querySelectorAll('video').forEach((video) => {
                    const videoStyle = getComputedStyle(video);
                    const display = videoStyle.getPropertyValue('display');
                    if (this.activeIndex !== index) {
                        video.pause();
                        video.currentTime = 0; // 動画を0秒に巻き戻し
                    } else if (display !== 'none') {
                        // アクティブなスライドのvideoを再生
                        video.play();
                    }
                });
            });
        },
    },
});

const newsSwiper = new Swiper('.top__news-swiper', {
    // 次のスライドをチラ見せ
    slidesPerView: 1.2,
    loop: true,
    spaceBetween: 2,
    // 前後の矢印
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    //　ブレイクポイント
    breakpoints: {
        1020: {
            slidesPerView: 3.2,
        },
    },

    mousewheel: {
        forceToAxis: true,
        sensitivity: 3,
    },
});
